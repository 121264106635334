<template>
  <form>
    <!--begin: Head -->
    <div
            class="kt-head kt-head--skin-dark"
            :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h3 class="kt-head__title">
        تېزلەتمە كونۇپكا
      </h3>
    </div>
    <!--end: Head -->
    <!--begin: Grid Nav -->
    <div class="kt-grid-nav kt-grid-nav--skin-light">
      <div v-for="action in actions" class="kt-grid-nav__row">
        <template v-for="item in action" >
          <template v-if="item.hasOwnProperty('to')">
            <router-link :to="item.to" class="kt-grid-nav__item">
          <span class="kt-grid-nav__icon">
<!--            <b-img :src="item.icon" :alt="item.title" svg-inline class="kt-svg-icon kt-svg-icon&#45;&#45;success kt-svg-icon&#45;&#45;lg" />-->
            <img
                    svg-inline
                    :src="`${item.icon}`"
                    class="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg"
                    :alt="item.title"
            />
          </span>
              <span class="kt-grid-nav__title">{{ item.title }}</span>
              <span class="kt-grid-nav__desc">{{ item.description }}</span>
            </router-link>
          </template>
          <template v-else-if="item.hasOwnProperty('click')">
            <a @click="item.click" class="kt-grid-nav__item">
              <span class="kt-grid-nav__icon">
    <!--            <b-img :src="item.icon" :alt="item.title" svg-inline class="kt-svg-icon kt-svg-icon&#45;&#45;success kt-svg-icon&#45;&#45;lg" />-->
                <img
                        svg-inline
                        :src="`${item.icon}`"
                        class="kt-svg-icon kt-svg-icon--success kt-svg-icon--lg"
                        :alt="item.title"
                />
              </span>
              <span class="kt-grid-nav__title">{{ item.title }}</span>
              <span class="kt-grid-nav__desc">{{ item.description }}</span>
            </a>
          </template>
        </template>
      </div>
    </div>
    <!--end: Grid Nav -->
  </form>
</template>

<script>
  import {REFRESH_TOKEN} from "../../../store/modules/auth.module";

  export default {
    name: "KTDropdownQuickAction",
    computed: {
      backgroundImage() {
        return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
      }
    },
    data() {
      return {
        actions: [
          [
            {
              name: 'فىلىم',
              description: 'يېڭى فىلىم قوشۇش',
              icon: '/assets/media/icons/svg/Clothes/Tie.svg',
              to: {name: 'film.store'},
            },
            {
              name: 'زاكاز',
              description: 'بارلىق زاكاز ئۇچۇرلىرى',
              icon: '/assets/media/icons/svg/General/Shield-check.svg',
              to: '',
            },
          ],
          [
            {
              name: 'ئېلان',
              description: 'يېڭىدىن ئېلان قوشۇش',
              icon: '/assets/media/icons/svg/General/Attachment1.svg',
              to: {name: 'advert.store'},
            },
            {
              name: 'ئاككونىت',
              description: 'ئاككونت ئۇچۇرىنى يېڭىلاش',
              icon: '/assets/media/icons/svg/General/Shield-check.svg',
              click: () => {
                this.$store.dispatch(REFRESH_TOKEN);
                let mEvent = new MouseEvent('click', {});
                this.$parent.$refs['dropdown-1'].dispatchEvent(mEvent);
              },
            }
          ]
        ]
      }
    },
    methods: {
      hideQuickAction(){
        $('.kt-header__topbar-item')[0].removeClass('show');
      }
    }
  };
</script>
